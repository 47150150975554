<template>
  <b-card-code
    no-body
    title="Role List"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">

        <div class="col-md-6">
          <b-button
            id="button--add__role"
            v-if="checkPermission('roles and permission management')"
            v-b-modal.form-modal
            class="btn btn-info btn-sm mb-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Add Role"
            @click="cleanUpForm()"
          >
            <feather-icon
              icon="PlusCircleIcon"
            />
            Add Role
          </b-button>
        </div>
      </div>
    </div>
    <b-modal
      id="form-modal"
      no-close-on-backdrop
      size="lg"
      :title="editid != null ? 'Edit Role' : 'Add Role'"
    >
      <div class="form">
        <div class="form-group">
          <label for="name">Role Name:</label>
          <validation-provider
            name="Enter role name, example: finance"
          >
            <b-form-input
              v-model="formPayload.name"
              :state="validations.name ? false:null"
              placeholder="Enter role name, example: finance"
            />
            <small
              v-for="(validation, index) in validations.name"
              v-if="validations.name"
              :key="`errorName${index}`"
              class="text-danger"
            >
              {{ validation }}<br />
            </small>
          </validation-provider>
        </div>

        <div class="form-group">
          <div
            v-for="permissionItem, key in permissions"
            :key="key"
            class="row mt-1"
          >
            <div class="col-12" @click="selectAll(permissionItem)">
              <label><strong>{{ key }}</strong></label>:
            </div>
            <div
              v-for="permission in permissionItem"
              :key="permission.id"
              class="col-4 mb-0"
            >
              <div class="form-group">
                <validation-provider
                  name="Enter role name, example: finance"
                >
                  <b-form-checkbox
                    class="text-capitalize"
                    :id="'permission_checkbox_' + permission.id"
                    v-model="formPayload.permissions"
                    :value="permission.id"
                    :state="validations.permissions? false:null"
                    placeholder="Enter role name, example: finance"
                  >
                    {{ permission.name }}
                  </b-form-checkbox>
                  <small
                    v-for="(validation, index) in validations.permissions"
                    v-if="validations.permissions"
                    :key="`errorName${index}`"
                    class="text-danger"
                  >
                    {{ key == 'Dashboard' ? validation : '' }}<br />
                  </small>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{}">
        <b-button
          variant="success"
          @click="editid == null ? createItem() : updateItem()"
        >
          Save Role
        </b-button>
      </template>
    </b-modal>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th width="15%">
              Name
            </th>
            <th>Permissions</th>
            <!-- <th>Creation Time</th> -->
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td
              colspan="3"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in result.data"
            v-else
            :key="item.id"
          >
            <td class="align-top text-capitalize">
              {{ item.name }}
            </td>
            <td class="text-capitalize">
              {{ item.permissions.map(o => o.name).join(', ') }}
            </td>
            <!-- <td>{{ item.created_at }}</td> -->
            <td class="float-right">
              <div
                class="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  v-if="item.name != 'super admin'"
                  class="btn btn-info btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                  @click="editItem(item)"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                </button>
                <button
                  v-if="item.name != 'super admin'"
                  class="btn btn-danger btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Delete"
                  @click="deleteItem(item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                  />
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="3"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="result.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'

Vue.use(VuejsDialog)

export default {
  title() {
    return `Roles Management`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner,
    ValidationProvider,
    BFormInput,
    BFormCheckbox,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      isLoading: false,
      formPayload: Object,
      editid: null,
      permissions: [],
      validations: '',
    }
  },
  created() {
    this.getData()
    this.getPermissions()
  },
  methods: {
    selectAll(item) {
      // this.formPayload.permissions = []
      // eslint-disable-next-line no-restricted-syntax
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          const element = item[key]
          this.formPayload.permissions.push(element.id)
        }
      }
    },
    getPermissions() {
      this.$http.get('/admin/acl/permissions')
        .then(response => {
          this.permissions = response.data.data
        })
    },
    cleanUpForm() {
      this.editid = null
      this.formPayload = {
        name: '',
        permissions: [],
      }
      this.validations = ''
    },
    createItem() {
      const form = this.preparePayload()

      this.$http.post('/admin/acl/roles', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Role successfully created')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
          }
        })
    },
    updateItem() {
      const form = this.preparePayload()
      form.append('_method', 'PATCH')

      this.$http.post(`/admin/acl/roles/${this.editid}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Role successfully updated!')
          this.cleanUpForm()
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
          }
        })
    },
    preparePayload() {
      const form = new FormData()
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      // Prepare permissions payload
      this.formPayload.permissions.forEach(item => {
        form.append('permissions[]', item)
      })

      return form
    },
    editItem(item) {
      this.cleanUpForm()
      this.editid = item.id

      this.formPayload = {
        name: item.name,
        permissions: item.permissions.map(o => o.id),
      }

      this.$bvModal.show('form-modal')
    },
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = {}
      queryParams.page = this.currentPage

      this.$http.get('admin/acl/roles', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`admin/acl/roles/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Item successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
  },
}
</script>
